<template>
  <div class="flex justify-center bg-white h-screen overflow-y-scroll">
    <div class="c23">
      <MainLogo class="flex justify-center items-center my-n-5xl"/>
      <p class="c25"><span class="c24">DIBBS PRIVACY POLICY</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">PLEASE READ THIS PRIVACY POLICY (THIS &ldquo;AGREEMENT&rdquo;) CAREFULLY BEFORE USING
          ANY SERVICES (AS DEFINED BELOW) OFFERED BY COLLECTIBLE HOLDINGS, INC. (&ldquo;Dibbs&rdquo;, &ldquo;WE&rdquo; OR
          &ldquo;US&rdquo;) INCLUDING ANY OF OUR WEBSITES AND MOBILE APPLICATIONS (collectively, the
          &ldquo;Services&rdquo;).</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10 c17">If you have any questions about this Policy, please submit your request via our
          Support Portal at: </span><a href="mailto:notice@dibbs.io" class="c11 c10 c70">notice@dibbs.io</a></p>
      <p class="c1"><span class="c11 c10 c18"></span></p>
      <p class="c6"><span class="c11 c10">ACCEPTANCE OF THIS PRIVACY POLICY </span></p>
      <p class="c1"><span class="c11 c10"></span></p>
      <p class="c6"><span class="c3">By accessing and using our Services, you signify acceptance to the terms of this
          Privacy Policy. Where we require your consent to process your personal information, we will ask for your consent
          to the collection, use, and disclosure of your personal information as described further below. We may provide
          additional &quot;just-in-time&quot; disclosures or information about the data processing practices of specific
          Services. These notices may supplement or clarify our privacy practices or may provide you with additional choices
          about how we process your data.</span></p>
      <p class="c6"><span class="c3">If you do not agree with or you are not comfortable with any aspect of this Privacy
          Policy, you should immediately discontinue access or use of our Services.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">CHANGES TO THIS PRIVACY POLICY</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">We may modify this Privacy Policy from time to time which will be indicated by
          changing the date at the top of this page. If we make any material changes, we will notify you by email (sent to
          the email address specified in your account), by means of a notice on our Services prior to the change becoming
          effective, or as otherwise required by law.</span></p>
      <p class="c1"><span class="c5"></span></p>
      <p class="c1"><span class="c5"></span></p>
      <p class="c6"><span class="c5">OUR RELATIONSHIP TO YOU</span></p>
      <p class="c1"><span class="c5"></span></p>
      <p class="c6"><span class="c10">For the purposes of this Privacy Policy, </span><span class="c3">&nbsp;your personal
          information is processed by Dibbs in relation to the Services provided to you (typically referred to as a
          &ldquo;data controller&rdquo;).</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">You may be asked to provide personal information anytime you are in contact with any
          Dibbs affiliates. The Dibbs affiliates &nbsp;may share your personal information with each other and use it
          consistent with this Privacy Policy. They may also combine it with other information to provide and improve our
          products, services, and content (additional details below). For example, even if you do not reside in the United
          States (the &ldquo;US&rdquo;), your information may be shared with Dibbs. which provides global support for all
          Services including technical infrastructure, product development, security, compliance, fraud prevention, and
          customer support.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c8"><span class="c10">If you have any questions about your Account, your personal information, or this
          Privacy Policy, please submit your request to </span><a href="mailto:privacy@dibbs.io" class="c10 c19">privacy@dibbs.io</a><span class="c11 c10">. </span></p>
      <p class="c8 c26"><span class="c11 c10 c18"></span></p>
      <p class="c8"><span class="c11 c10">PERSONAL INFORMATION DIBBS COLLECTS</span></p>
      <p class="c6"><span class="c10 c19">We collect information to provide better services to our users - </span><span
          class="c3">this includes data that identifies an individual or relates to an identifiable individual, also known
          as personal information. This includes information you provide to us, information which is collected about you
          automatically, and information we obtain from third parties. The definition of personal information depends on the
          applicable law based on your physical location. Only the definition that applies to your physical location will
          apply to you under this Privacy Policy. &nbsp;</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">The information Dibbs collects, and how that information is used, depends on how you
          use our services.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">PERSONAL INFORMATION YOU PROVIDE TO US</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">When you create a Dibbs Account, you provide us with personal information that
          includes your name and a password. &nbsp;You can also choose to add a phone number or payment information to your
          account. &nbsp;</span><span class="c5">This information is either required by law (e.g. to verify your identity),
          necessary to provide the requested services (e.g. you will need to provide your bank account number if you&#39;d
          like to link that account to your Dibbs Account), or is relevant for certain specified purposes, described in
          greater detail below. As we add new features and Services, you may be asked to provide additional
          information.</span></p>
      <p class="c1"><span class="c5"></span></p>
      <p class="c6"><span class="c3">Please note that we may not be able to serve you as effectively or offer you our
          Services if you choose not to share certain information with us. Any information you provide to us that is not
          required is voluntary.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">We may collect the following types of information from you:</span></p>
      <p class="c1"><span class="c3"></span></p>
      <ul class="c12 lst-kix_565v9vpjzg1f-0 start">
        <li class="c7 li-bullet-0"><span class="c13">Personal Identification Information</span><span class="c11 c10">: Full
            name, date of birth, nationality, gender, signature, utility bills, photographs, phone number, home address,
            and/or email.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Formal Identification Information</span><span class="c11 c10">:
            Government issued identity document such as Passport, Driver&#39;s License, National Identity Card, State ID
            Card, Tax ID number, passport number, driver&#39;s license details, national identity card details, visa
            information, and/or any other information deemed necessary to comply with our legal obligations under financial
            or anti-money laundering laws.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Institutional Information</span><span class="c11 c10">: Employer
            Identification number (or comparable number issued by a government), proof of legal formation (e.g. Articles of
            Incorporation), personal identification information for all material beneficial owners.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Financial Information</span><span class="c11 c10">: Bank account
            information, payment card primary account number (PAN), transaction history, trading data, and/or tax
            identification.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Transaction Information</span><span class="c10 c11">: Information about
            the transactions you make on our Services, such as the name of the recipient, your name, the amount, and/or
            timestamp.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Employment Information</span><span class="c11 c10">: Office location,
            job title, and/or description of role.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Account Activity</span><span class="c11 c10">: Survey responses,
            information provided to our support team or user research team.</span></li>
        <li class="c7 li-bullet-0"><span class="c14 c10">Other Personal Information or commercial and/or identification
            information &ndash; Whatever information we, in our sole discretion, deem necessary to comply with our legal
            obligations under various anti-money laundering (AML) obligations, such as under the European Union&rsquo;s 4th
            AML Directive and the U.S. Bank Secrecy Act (BSA).</span></li>
      </ul>
      <p class="c6"><span class="c3">INFORMATION WE COLLECT FROM YOU AUTOMATICALLY </span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">We may collect certain types of information automatically, such as whenever you
          interact with the Sites or use the Services through the use of cookies, pixels and other third party technologies
          (collectively, &ldquo;cookies&rdquo;). This information helps us recognize your browser or device, learn more
          about your interests, and provide you with essential features and services and for additional purposes, including:
          &nbsp;</span></p>
      <p class="c1"><span class="c3"></span></p>
      <ul class="c12 lst-kix_tf85rhmc6ri5-0 start">
        <li class="c2 li-bullet-0"><span class="c3">Addressing customer support issues.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">Improving the performance of our Sites and applications.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">Providing you with a streamlined and personalized experience.</span>
        </li>
        <li class="c2 li-bullet-0"><span class="c10">Preventing fraudulent activity and improving the security of our
            Site.</span></li>
      </ul>
      <ul class="c12 lst-kix_j061zxvt4qwn-0 start">
        <li class="c2 li-bullet-0"><span class="c10">Recognizing you when you sign-in to use our services. &nbsp;By
            collecting for example, </span><span class="c3">browser fingerprint, operating system, browser name and version,
            and/or personal IP addresses.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">Keeping track of your specified preferences.</span></li>
      </ul>
      <p class="c1 c21"><span class="c3"></span></p>
      <p class="c6"><span class="c3">COOKIES NOTICE</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">Dibbs&rsquo; use of cookies allows you to take advantage of some of the Service&rsquo;s
          essential features. &nbsp;If you block or otherwise reject certain cookies, you may not be able to utilize certain
          features associated with the Services. &nbsp; </span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">Approved third parties may also set cookies, or provide analytics when you interact
          with the Services. &nbsp;Third parties include search engines, providers of measurement and data analytics
          services, social media networks and advertising companies. &nbsp;Third parties use cookies in the process of
          delivering content, including personalized advertisements. &nbsp;</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">You can manage cookies through your browser settings. &nbsp;The &lsquo;Help&rsquo;
          feature on most browsers will tell you how to prevent your browser from accepting new cookies, how to have the
          browser notify you when you receive a new cookie, how to block cookies, and when cookies will expire. &nbsp;If you
          block all cookies on your browser, neither we nor third parties will transfer cookies to your browser, unless they
          are essential. &nbsp;If you do this however, you may have to manually adjust user preferences every time you visit
          a site and some features and services may not work.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">INFORMATION WE GATHER FROM THIRD PARTIES</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">We obtain information about you in a number of ways through your use of our services,
          including through any of our websites, the account opening process, webinar sign-up forms, event subscribing, news
          and updates subscribing, and from information provided in the course of on-going support service communications.
          We also receive information about you from third parties such as your payment providers and through publicly
          available sources. </span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">These sources may include:</span></p>
      <p class="c1"><span class="c3"></span></p>
      <ul class="c12 lst-kix_h28nbo2pqc9o-0 start">
        <li class="c2 li-bullet-0"><span class="c3">Public Databases, Credit Bureaus &amp; ID Verification Partners: We
            obtain information about you from public databases and ID verification partners for purposes of verifying your
            identity in accordance with applicable law. &nbsp; We obtain such information to comply with our legal
            obligations, such as anti-money laundering laws. In some cases, we may process additional data about you to
            assess risk and ensure our Services are not used fraudulently or for other illicit activities. In such
            instances, processing is necessary for us to continue to perform our contractual obligations with you and
            others. </span></li>
        <li class="c2 li-bullet-0"><span class="c10">Blockchain Data: We may analyze public blockchain data to ensure
            parties utilizing our Services are not engaged in illegal or prohibited activity under our Terms, and to analyze
            transaction trends for research and development purposes.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">Joint Marketing Partners &amp; Resellers: For example, unless prohibited
            by applicable law, joint marketing partners or resellers may share information about you with us so that we can
            better understand which of our Services may be of interest to you.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">Advertising Networks &amp; Analytics Providers: We work with these
            providers to provide us with de-identified information about how you found our Sites and how you interact with
            the Sites and Services. This information may be collected prior to account creation - for more information on
            how you can manage collection of this data.</span></li>
      </ul>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">HOW WE USE THE INFORMATION COLLECTED ABOUT YOU</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">Our primary purpose in collecting personal information is to provide you with a secure,
          smooth, efficient, and customized experience. We generally use personal information to create, develop, operate,
          deliver, and improve our Services, content and advertising; and for loss prevention and anti-fraud
          purposes.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">We may use this information in the following ways:</span></p>
      <p class="c1"><span class="c3"></span></p>
      <ol class="c12 lst-kix_8zljhqsxnmsr-0 start" start="1">
        <li class="c2 li-bullet-0"><span class="c3">To provide our services and improve them - we use your information to
            deliver our services, like processing the transactions you enter, or the collectibles you search for in order to
            return the right results. &nbsp; &nbsp;We also use your information to ensure our services work as intended,
            including to track outages, and troubleshoot issues you report to us. &nbsp;We may also use your information to
            make improvements to our services.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">To enforce the terms in the <router-link class="c70" to="/user-agreement" target="_blank" rel="noopener noreferrer">Dibbs User Agreement</router-link> and other agreements -
            Dibbs handles sensitive information, such as your identification and financial data, so it is very important for
            us and our customers that we actively monitor, investigate, prevent, and mitigate any potentially prohibited or
            illegal activities, enforce our agreements with third parties, and/or prevent and detect violations of our
            posted user agreement or agreements for other Services. In addition, we may need to collect fees based on your
            use of our Services. We collect information about your account usage and closely monitor your interactions with
            our Services. We may use any of your personal information collected for these purposes. The consequences of not
            processing your personal information for such purposes is the termination of your account.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">To protect Dibbs, our users and the community - we use information to
            help improve the safety and reliability of our services. &nbsp;This includes detecting, preventing and
            responding to instances of fraud, abuse, security risks and technical issues that could harm Dibbs, our users or
            the general public.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">To Communicate with you - We use information we collect, like your email
            address, to interact with you directly. &nbsp;For example, Dibbs may send administrative or account-related
            information to you to keep you updated about our Services, inform you of relevant security issues or updates, or
            provide other transaction-related information. You may not opt-out of receiving critical service communications,
            such as emails or mobile notifications sent for legal or security purposes.</span></li>
        <li class="c2 li-bullet-0"><span class="c10">To safeguard Dibbs&rsquo; legitimate interests - We process your data
            to safeguard the legitimate interests pursued by us or by a third party. &nbsp;A legitimate interest is when we
            have a business or commercial purpose to use your information. &nbsp;Examples of such legitimate interests
            include risk management, aggregate reporting and analysis for managing the business and development of products
            and services, or to provide you with personalized services.</span></li>
        <li class="c2 li-bullet-0"><span class="c10">To engage in marketing activities - </span><span class="c3">Based on
            your communication preferences, we may send you marketing communications (e.g. emails or mobile notifications)
            to inform you about our events or our partner events; to deliver targeted marketing; and to provide you with
            promotional offers. Our marketing will be conducted in accordance with your advertising marketing preferences
            and as permitted by applicable law.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">For any purpose you consent to - We may disclose your information for
            any purpose you consent to. &nbsp;We will not use your personal information for purposes other than those
            purposes we have disclosed to you, without your permission. </span></li>
      </ol>
      <p class="c1"><span class="c3"></span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">SHARING YOUR INFORMATION WITH THIRD PARTIES</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c24">Dibbs will never sell or rent your personal information to third parties without your
          explicit consent.</span></p>
      <p class="c1"><span class="c13 c20"></span></p>
      <p class="c6"><span class="c10 c17">Dibbs takes care to share your information only with third parties who have a
          legitimate purpose for accessing your data. &nbsp;</span><span class="c10">Our contracts require these service
          providers to only use your information in connection with the services they perform for us, and prohibit them from
          selling your information to anyone else. &nbsp;</span><span class="c10">We also require that service providers and
          business partners who process personal information to acknowledge the confidentiality of this information,
          undertake to respect any client&rsquo;s right to privacy and comply with all relevant privacy and data protection
          laws and this Privacy Notice. &nbsp;</span></p>
      <p class="c1"><span class="c5"></span></p>
      <p class="c6"><span class="c10 c17">Dibbs uses Plaid Inc. (&ldquo;Plaid&rdquo;) to gather your
      data from financial institutions. By using our service, you grant Dibbs and Plaid the right, power, and authority
      to act on your behalf to access and transmit your personal and financial information from the relevant financial
      institution. You agree to your personal and financial information being transferred, stored, and processed by
      Plaid in accordance with the <a class="c10 c70"
        href="https://plaid.com/legal/#end-user-privacy-policy" target="_blank" rel="noopener noreferrer">Plaid
        Privacy Policy</a>.</span></p>
      <p class="c1"><span class="c5"></span></p>
      <p class="c6"><span class="c5">Some examples of where Dibbs may disclose your information to third parties
          includes:</span></p>
      <p class="c1"><span class="c3"></span></p>
      <ul class="c12 lst-kix_cyigb1g4ojxw-0 start">
        <li class="c2 li-bullet-0"><span class="c3">Any members of the Company, which means that any of our affiliates and
            subsidiaries may receive such information;</span></li>
        <li class="c2 li-bullet-0"><span class="c3">Any of our service providers and business partners, for business
            purposes, such as specialist advisors who have been contracted to provide us with administrative, financial,
            legal, tax, compliance, insurance, IT, debt-recovery, analytics, research or other services;</span></li>
        <li class="c2 li-bullet-0"><span class="c3">With third party identity verification services in order to prevent
            fraud. &nbsp;These service providers may create derivative data based on your personal information that can be
            used in connection with the provision of identity verification and fraud prevention services. </span></li>
        <li class="c2 li-bullet-0"><span class="c10">With financial institutions with which we partner to process payments
            you have authorized.</span></li>
        <li class="c2 li-bullet-0"><span class="c3">With our professional advisors who provide banking, legal, compliance,
            insurance, accounting, or other consulting services in order to complete third party financial, technical,
            compliance and legal audits of our operations or otherwise comply with our legal obligations.</span></li>
        <li class="c2 li-bullet-0"><span class="c10">With law enforcement, officials, or other third parties when we are
            compelled to do so by a subpoena, court order, or similar legal procedure, or when we believe in good faith that
            the disclosure of personal information is necessary to prevent physical harm or financial loss, to report
            suspected illegal activity, or to investigate violations of our <router-link class="c70" to="/user-agreement" target="_blank" rel="noopener noreferrer">User Agreement</router-link> or any other applicable
            policies.</span></li>
      </ul>
      <p class="c1"><span class="c5"></span></p>
      <p class="c6"><span class="c3">From time to time, and as required under the applicable law, we may request your
          permission to allow us to share your personal information with third parties beyond the contexts outlined above.
          You may opt out of having your personal information shared with third parties, or allowing us to use your personal
          information for any purpose that is incompatible with the purposes for which we originally collected it or
          subsequently obtained your authorization. If you choose to so limit the use of your personal information, certain
          features or the Services may not be available to you.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">WHERE WE STORE YOUR INFORMATION</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10 c15">Our operations are supported by a network of computers, servers, and other
          infrastructure and information technology, including, but not limited to, third-party service providers. We and
          our third-party service providers and business partners store and process your personal data in the </span><span
          class="c10 c15">United States of America</span><span class="c14 c10">.</span></p>
      <p class="c1"><span class="c14 c10"></span></p>
      <p class="c6"><span class="c14 c10">CROSS BORDER DATA TRANSFERS</span></p>
      <p class="c1"><span class="c10 c14"></span></p>
      <p class="c6"><span class="c3">To facilitate our global operations, Dibbs may transfer, store, and process your
          information within our family of companies, partners, and service providers based throughout the world, including
          the UK, the US, and possibly other countries. We contractually obligate recipients of your personal information to
          agree to at least the same level of privacy safeguards as required under applicable data protection laws. By
          communicating electronically with Dibbs, you acknowledge and agree to your personal information being processed in
          this way.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">If you have a complaint about our privacy practices and our collection, use or
          disclosure of personal information please submit your request to </span><a href="mailto:privacy@dibbs.io" class="c10">privacy@dibbs.io</a><span class="c3">.</span></p>
      <p class="c1"><span class="c0"></span></p>
      <p class="c6"><span class="c3">YOUR RIGHTS REGARDING YOUR PERSONAL DATA</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">Depending on applicable law where you reside, you may be able to assert certain rights
          related to your personal information identified below. If any of the rights listed below are not provided under
          law for your operating entity or jurisdiction, Dibbs has absolute discretion in providing you with those
          rights.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">Your rights to personal information are not absolute. Depending upon the applicable
          law, access may be denied: (a) when denial of access is required or authorized by law; (b) when granting access
          would have a negative impact on another&#39;s privacy; (c) to protect our rights and properties; (d) where the
          request is frivolous or vexatious, or for other reasons.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <ul class="c12 lst-kix_x20wxcmev7gz-0 start">
        <li class="c7 li-bullet-0"><span class="c13">Access and portability</span><span class="c11 c10">. You may request
            that we provide you a copy of your personal information held by us. This information will be provided without
            undue delay subject to a potential fee associated with gathering of the information (as permitted by law),
            unless such provision adversely affects the rights and freedoms of others. In certain circumstances, you may
            request to receive your personal information in a structured, commonly used and machine-readable format, and to
            have us transfer your personal information directly to another data controller.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Rectification of incomplete or inaccurate personal
            information</span><span class="c11 c10">. You may request us to rectify or update any of your personal
            information held by Dibbs that is inaccurate. You may do this at any time by logging in to your account and
            clicking the Profile or My Account tab.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Erasure</span><span class="c11 c10">. You may request to erase your
            personal information, subject to applicable law. If you close your Dibbs Account, we will mark your account in
            our database as &quot;Closed,&quot; but will keep certain account information, including your request to erase,
            in our database for a period of time as described above. This is necessary to deter fraud, by ensuring that
            persons who try to commit fraud will not be able to avoid detection simply by closing their account and opening
            a new account, and to comply with Dibb&rsquo;s legal obligations. However, if you close your account, your
            personal information will not be used by us for any further purposes, nor shared with third parties, except as
            necessary to prevent fraud and assist law enforcement, as required by law, or in accordance with this Privacy
            Policy.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Withdraw consent</span><span class="c11 c10">. To the extent the
            processing of your personal information is based on your consent, you may withdraw your consent at any time.
            Your withdrawal will not affect the lawfulness of Dibb&rsquo;s processing based on consent before your
            withdrawal.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Restriction of processing</span><span class="c11 c10">. In some
            jurisdictions, applicable law may give you the right to restrict or object to us processing your personal
            information under certain circumstances. We may continue to process your personal information if it is necessary
            for the defense of legal claims, or for any other exceptions permitted by applicable law.</span></li>
        <li class="c7 li-bullet-0"><span class="c13">Automated individual decision-making, including profiling</span><span
            class="c10 c19">. Dibbs relies on automated tools to help determine whether a transaction or a customer account
            presents a fraud or legal risk. In some jurisdictions, you have the right not to be subject to a decision based
            solely on automated processing of your personal information, including profiling, which produces legal or
            similarly significant effects on you, save for the exceptions applicable under relevant data protection
            laws.</span></li>
      </ul>
      <p class="c6"><span class="c3">MAKING A PRIVACY REQUEST OR OBJECTION</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">You can make privacy rights requests relating to your personal information by
          contacting us via </span><a href="mailto:privacy@dibbs.io" class="c10">privacy@dibbs.io</a><span
          class="c3">. </span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c14 c10">OUR PRODUCTS AND SERVICES ARE NOT AVAILABLE TO CHILDREN</span></p>
      <p class="c1"><span class="c14 c10"></span></p>
      <p class="c6"><span class="c10">Our products and services are not directed to persons under the age of 18, hereinafter
          &ldquo;Children&rdquo;, &ldquo;Child&rdquo; and we do not knowingly collect personal information from Children. If
          we learn that we have inadvertently gathered personal information from a Child, we will take legally permissible
          measures to remove that information from our records. The Company will require the user to close his or her
          account and will not allow the use of our products and services. If you are a parent or guardian of a Child, and
          you become aware that a Child has provided personal information to us, please contact us at </span><a href="mailto:notice@dibbs.io"
          class="c10">notice@dibbs.io</a><span class="c10">&nbsp;and you may request to exercise your applicable access,
          rectification, cancellation, and/or objection rights.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">HOW TO FILE A COMPLAINT</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">If you believe that we have infringed upon your privacy rights, or any other rights in
          connection with this Privacy Policy, we encourage you to contact us via our </span><span class="c10 c70 cursor-pointer" @click="goToExternalPage('https://help.dibbs.io/')">Support Page</span><span class="c3">&nbsp;or by emailing <a href="mailto:notice@dibbs.io">notice@dibbs.io</a>
          &nbsp;so that Dibbs can try to resolve your issue or dispute informally. &nbsp;</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c3">HAVE SOMETHING ELSE TO CONTACT US ABOUT?</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c6"><span class="c10">If you have questions or concerns regarding this Privacy Policy, or if you have a
          complaint, please contact us </span><span class="c10">at <a href="mailto:notice@dibbs.io">notice@dibbs.io</a></span><span class="c10">.</span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c1"><span class="c3"></span></p>
      <p class="c1"><span class="c5"></span></p>
    </div>
  </div>
</template>

<script>
import MainLogo from '@/stories/misc/MainLogo.vue';

export default {
  name: 'PrivacyPolicy',
  components: {
    MainLogo,
  },
  async mounted() {
    await this.$store.dispatch('events/track', {
      event: 'TERMS_AND_CONDITIONS_VIEWED',
      variables: {
        term: 'Privacy Policy',
      },
    });
  },
  methods: {
    async goToExternalPage(page) {
      this.goToExternalPageGlobal(page);
      await this.$store.dispatch('events/track', {
        event: 'SUPPORT_VIEWED',
      });
    },
  }
};
</script>

<style scoped>
li.li-bullet-0:before {
  margin-left: -18pt;
  white-space: nowrap;
  display: inline-block;
  min-width: 18pt;
}

ul.lst-kix_565v9vpjzg1f-0 {
  list-style-type: none;
}

.lst-kix_tf85rhmc6ri5-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_tf85rhmc6ri5-0 {
  list-style-type: none;
}

.lst-kix_j061zxvt4qwn-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_j061zxvt4qwn-0 {
  list-style-type: none;
}

.lst-kix_h28nbo2pqc9o-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_h28nbo2pqc9o-0 {
  list-style-type: none;
}

.lst-kix_cyigb1g4ojxw-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_cyigb1g4ojxw-0 {
  list-style-type: none;
}

.lst-kix_x20wxcmev7gz-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_x20wxcmev7gz-0 {
  list-style-type: none;
}

.lst-kix_565v9vpjzg1f-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_565v9vpjzg1f-0 {
  list-style-type: none;
}

.lst-kix_x20wxcmev7gz-0 > li:before {
  content: '\0025cf  ';
}

ul.lst-kix_x20wxcmev7gz-0 {
  list-style-type: none;
}

ol.lst-kix_8zljhqsxnmsr-0.start {
  counter-reset: lst-ctn-kix_8zljhqsxnmsr-0 0;
}
.lst-kix_8zljhqsxnmsr-0 > li {
  counter-increment: lst-ctn-kix_8zljhqsxnmsr-0;
}

.lst-kix_8zljhqsxnmsr-0 > li:before {
  content: '' counter(lst-ctn-kix_8zljhqsxnmsr-0, decimal) '. ';
}

ol.lst-kix_8zljhqsxnmsr-0 {
  list-style-type: none;
}

ol {
  margin: 0;
  padding: 0;
}
.c7 {
  background-color: #ffffff;
  margin-left: 20pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 5pt;
  line-height: 1.4285714285714286;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c5 {
  background-color: #ffffff;
  color: #050f19;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman', sans-serif;
  font-style: normal;
}
.c0 {
  background-color: #ffff00;
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman', sans-serif;
  font-style: normal;
}
.c2 {
  margin-left: 20pt;
  padding-top: 0pt;
  padding-left: 0pt;
  padding-bottom: 5pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c1 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
  height: 11pt;
}
.c8 {
  background-color: #ffffff;
  padding-top: 0pt;
  padding-bottom: 17pt;
  line-height: 1;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c4 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 11pt;
  font-family: Arial, sans-serif;
  font-style: normal;
}
.c3 {
  color: #000000;
  font-weight: 400;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman', sans-serif;
  font-style: normal;
}
.c24 {
  color: #000000;
  font-weight: 700;
  text-decoration: none;
  vertical-align: baseline;
  font-size: 12pt;
  font-family: 'Times New Roman', sans-serif;
  font-style: normal;
}
.c6 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: left;
}
.c25 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: center;
}
.c22 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1.15;
  orphans: 2;
  widows: 2;
  text-align: right;
}
.c20 {
  background-color: #ffffff;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c13 {
  font-size: 12pt;
  font-family: 'Times New Roman', sans-serif;
  color: #050f19;
  font-weight: 700;
}
.c9 {
  padding-top: 0pt;
  padding-bottom: 0pt;
  line-height: 1;
  text-align: left;
}
.c11 {
  color: #050f19;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c14 {
  color: #0e0c28;
  text-decoration: none;
  vertical-align: baseline;
  font-style: normal;
}
.c10 {
  font-size: 12pt;
  font-family: 'Times New Roman', sans-serif;
  font-weight: 400;
}
.c23 {
  background-color: #ffffff;
  max-width: 468pt;
  padding: 15pt;
}
.c17 {
  background-color: #ffffff;
  color: #050f19;
}
.c16 {
  border: 1px solid black;
  margin: 5px;
}
.c12 {
  padding: 0;
  margin: 0;
}
.c15 {
  color: #0e0c28;
}
.c26 {
  height: 11pt;
}
.c19 {
  color: #050f19;
}
.c21 {
  margin-left: 36pt;
}
.c18 {
  background-color: #ffff00;
}
li {
  color: #000000;
  font-size: 11pt;
  font-family: Arial, sans-serif;
}
p {
  margin: 0;
  color: #000000;
  font-size: 11pt;
  font-family: Arial, sans-serif;
}
.c70 {
  color: #1155cc;
  text-decoration: underline;
}
/* Scrollbar style */
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: #fff;
}
</style>
